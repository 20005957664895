
import { MetaSwiper } from "./meta-settings.js";
import { CountUp } from "./plugins/countUp.min.js";
import { Swiper } from "https://cdnjs.cloudflare.com/ajax/libs/Swiper/8.4.4/swiper-bundle.esm.browser.js";
import MetaMap from "./plugins/metaMap.v2.js";
import {
    computePosition,
    shift,
    flip,
    autoUpdate,
    offset,
} from "https://cdn.jsdelivr.net/npm/@floating-ui/dom@1.4.3/+esm";

document.addEventListener("DOMContentLoaded", function () {
    if (document.querySelector('.js-dropdown')) {
        const btn = document.querySelector('.js-dropdown');
        const btnChevron = btn.querySelector('.header__link--btn span');
        const btnTitle = btn.querySelector('.header__link--btn');
        const dropdown = document.querySelector('.header__dropdown');
        let timer;

        if (innerWidth > 1023) {
            btn.addEventListener('mouseenter', () => {
                btn.classList.add('open');
                dropdown.classList.add('open');
            });

            btn.addEventListener('mouseenter', () => {
                clearTimeout(timer);
            });

            btn.addEventListener('mouseleave', () => {
                timer = setTimeout(() => {
                    btn.classList.remove('open');
                    dropdown.classList.remove('open');
                }, 1000);
            });
        } else {
            btn.addEventListener('click', (e) => {
                if (e.target === btnChevron) {
                    e.preventDefault();
                    btn.classList.toggle('open');
                    dropdown.classList.toggle('open');
                }
            });

            btnTitle.addEventListener('click', (e) => {
                if (!btnTitle.classList.contains('open')) {
                    e.preventDefault();
                    e.stopPropagation()
                    btnTitle.classList.add('open');
                    btn.classList.add('open');
                    dropdown.classList.add('open');
                }
            });
        }

    }

    // swiper init on resize
    const resizableSwiper = (breakpoint, swiperClass, swiperSettings, callback) => {
        let swiper;

        breakpoint = window.matchMedia(breakpoint);

        const enableSwiper = function(className, settings) {
            swiper = new Swiper(className, settings);

            if (callback) {
                callback(swiper);
            }
        }

        const checker = function() {
            if (breakpoint.matches) {
                return enableSwiper(swiperClass, swiperSettings);
            } else {
                if (swiper !== undefined) swiper.destroy(true, true);
                return;
            }
        };

        breakpoint.addEventListener('change', checker);
        checker();
    }
    // swiper init on resize

    // choices
    const choicesArr = document.querySelectorAll(".js-choices");
    if (choicesArr) {
        choicesArr.forEach(select => {
            new Choices(select, {
                allowHTML: true,
                searchEnabled: false,
            });
        });
    }

    if (document.querySelector(".choices")) {
        const choicesWrappers = document.querySelectorAll(".choices");
        choicesWrappers.forEach(choiceWrap => {
            choiceWrap.addEventListener("click", () => {
                if (!choiceWrap.querySelector(".custom-simplebar-wrapper")) {
                    const selectSimplebar = choiceWrap.querySelector(
                        ".choices__list--dropdown .choices__list"
                    );
                    const div = document.createElement("div");
                    div.classList.add("custom-simplebar-wrapper");
                    const content = selectSimplebar.innerHTML;
                    selectSimplebar.innerHTML = "";
                    div.setAttribute("data-simplebar", "");
                    div.innerHTML = content;
                    selectSimplebar.appendChild(div);
                }
            });
        });
    }
    // end choices


    // care tabs
    function careContentToggler () {
        const btns = document.querySelectorAll(".care__btn");
        const content = document.querySelectorAll(".care__content");
        const bgs = document.querySelectorAll(".care__bg");

        btns.forEach((btn, i) => {
            btn.addEventListener("click", () => {
                [bgs, btns, content].forEach(arr => {
                    arr.forEach(item => {
                        item.classList.remove("active");
                    });
                });

                if (!i) {
                    [bgs, btns, content].forEach(arr => {
                        arr[1].classList.add("active");
                    });
                }

                if (i) {
                    [bgs, btns, content].forEach(arr => {
                        arr[0].classList.add("active");
                    });
                }
            });
        });
    }
    careContentToggler();
    // end care tabs

    // countUp
    const countsArr = [...document.getElementsByClassName(`counter__num`)];
    if (countsArr) {
        const defaultOptions = {
            separator: ",",
            enableScrollSpy: true,
            scrollSpyRunOnce: true,
        };

        countsArr.forEach(elem => {
            let html = elem.innerHTML;
            const number = html.match("\\d+")[0];
            let prefix = "";

            if (html.indexOf(number) > 0) {
                prefix = html.slice(0, html.indexOf(number));
                html = html.replace(prefix, "");
            }

            const suffix = html.replace(number, "");

            let optionsFromDataAttr = $(elem).data();

            for (const key in optionsFromDataAttr) {
                if (optionsFromDataAttr[key] === "") {
                    optionsFromDataAttr[key] = true;
                }
            }

            optionsFromDataAttr.prefix = prefix;
            optionsFromDataAttr.suffix = suffix;

            new CountUp(
                elem.id,
                number,
                Object.assign(Object.assign({}, defaultOptions), optionsFromDataAttr)
            );
        });
    }
    // end countUp

    AOS.init({
        startEvent: "load",
        offset: 150,
        delay: 0,
        duration: 500,
        easing: "ease",
        once: true,
    });

    // fix ios height
    if (innerWidth < 1024) {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
        window.addEventListener("resize", () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
        });
    }
    // end fix ios height
    // burger-menu
    const burgerMenu = (menuSelector, burgerSelector) => {
        const menuElem = document.querySelector(menuSelector),
            layoutElem = document.querySelector(".header__layout"),
            burgerElem = document.querySelector(burgerSelector);

        const removeClass = () => {
            menuElem.classList.remove("active");
            scrollLock.enablePageScroll();
        };
        removeClass();

        menuElem.querySelectorAll("a").forEach(i => {
            i.addEventListener("click", e => {
                if (menuElem.classList.contains("active")) {
                    removeClass();
                }
            });
        });

        burgerElem.addEventListener("click", () => {
            if (menuElem.classList.contains("active")) {
                removeClass();
            } else {
                menuElem.classList.add("active");
                var method = 'margin';
                scrollLock.setFillGapMethod(method);
                scrollLock.disablePageScroll();

                if (document.querySelector(".header__simplebar") && innerWidth < 1024) {
                    scrollLock.addScrollableSelector('.simplebar-content-wrapper');
                }

                scrollLock.addFillGapSelector('.header__navbar-box');
            }
        });

        window.addEventListener("resize", () => {
            if (innerWidth > 1023) {
                removeClass();
            }
        });

        layoutElem.addEventListener("click", () => {
            removeClass();
        });
    };

    if (document.querySelector(".header")) {
        burgerMenu(".header", ".header__burger");
    }
    // end burger-menu

    // sticky
    let scrollPoint;
    function stickyMenu() {
        const headerElem = document.querySelector(".header");

        if (window.scrollY >= 50) {
            headerElem.classList.add("sticky");
        } else {
            headerElem.classList.remove("sticky");
        }

        if (innerWidth > 1023) {
            if (window.scrollY > scrollPoint && window.scrollY >= 50) {
                headerElem.style.transform = "translateY(-100%)";
            } else {
                headerElem.style.transform = "unset";
            }

            scrollPoint = window.scrollY;
        }
    }

    window.addEventListener("scroll", stickyMenu);
    stickyMenu();
    // end sticky

    //  swiper
    if (document.querySelector(".prioritise__slider")) {
        MetaSwiper(`.prioritise__slider`, {
            loop: false,
            slidesPerView: 1,
            spaceBetween: 20,
            speed: 500,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
            breakpoints: {
                414: {
                    spaceBetween: 15,
                    slidesPerView: 2,
                },
                568: {
                    spaceBetween: 15,
                    slidesPerView: 3,
                },
                768: {
                    spaceBetween: 15,
                    slidesPerView: 4,
                },
                1024: {
                    spaceBetween: 15,
                    slidesPerView: 6,
                },
            },
            navigation: {
                nextEl: ".prioritise__next",
                prevEl: ".prioritise__prev",
            },
        });
    }

    if (document.querySelector(".follow-us")) {
        const sliderWrappers = document.querySelectorAll(".follow-us__slider-box");
        const slider = sliderWrappers[0].querySelector(".follow-us__slider");
        const prevArrow = sliderWrappers[0].querySelector('.slider-nav .arrow-btn:nth-child(1)');
        const nextArrow = sliderWrappers[0].querySelector('.slider-nav .arrow-btn:nth-child(2)');
        const container = document.querySelector(".follow-us");

        customTabs(
            container,
            ".follow-us__tab",
            ".follow-us__tab-content",
            sliderWrappers
        );

        new Swiper(slider, {
            loop: false,
            slidesPerView: 1,
            spaceBetween: 15,
            speed: 500,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
            navigation: {
                nextEl: nextArrow,
                prevEl: prevArrow,
            },
            breakpoints: {
                450: {
                    slidesPerView: 2,
                },
                652: {
                    slidesPerView: 3,
                },
                1024: {
                    slidesPerView: 4,
                },
            },
        });
    }

    if (document.querySelector(".gallery__slider")) {
        MetaSwiper(`.gallery__slider`, {
            loop: false,
            slidesPerView: 1,
            spaceBetween: 15,
            speed: 500,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
            breakpoints: {
                432: {
                    slidesPerView: 2,
                },
                652: {
                    slidesPerView: 3,
                },
                1024: {
                    slidesPerView: 4,
                },
            },
            navigation: {
                nextEl: ".gallery__next",
                prevEl: ".gallery__prev",
            },
        });
    }

    if (document.querySelector(".positions__slider")) {
        const swiper = new Swiper(`.positions__slider`, {
            loop: false,
            slidesPerView: 1,
            spaceBetween: 40,
            speed: 500,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
            navigation: {
                nextEl: ".positions__next",
                prevEl: ".positions__prev",
            },
            breakpoints: {
                652: {
                    spaceBetween: 15,
                    slidesPerView: 2,
                },
                1024: {
                    spaceBetween: 15,
                    slidesPerView: 3,
                },
            },
        });

        window.updatePositionSwiper = function () {
            swiper.update();
            swiper.slideTo(0);
        };
    }

    if (document.querySelector(".headlines")) {
        const sliderThumbs = new Swiper(".headlines__thumb", {
            watchSlidesProgress: true,
            freeMode: true,
            breakpoints: {
                0: {
                    direction: "horizontal",
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                770: {
                    direction: "horizontal",
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                1024: {
                    direction: "vertical",
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
            },
        });
        const sliderText = new Swiper(".headlines__video", {
            navigation: {
                nextEl: ".headlines__next",
                prevEl: ".headlines__prev",
            },
            speed: 500,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
            thumbs: {
                swiper: sliderThumbs,
            },
            breakpoints: {
                0: {
                    direction: "horizontal",
                    slidesPerView: 1,
                    spaceBetween: 40,
                },
                1024: {
                    direction: "vertical",
                },
            },
        });
    }

    if (document.querySelector(".counter__slider")) {
        MetaSwiper(`.counter__slider`, {
            loop: false,
            slidesPerView: 1,
            spaceBetween: 40,
            speed: 500,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
            navigation: {
                nextEl: ".counter__next",
                prevEl: ".counter__prev",
            },
            breakpoints: {
                427: {
                    spaceBetween: 15,
                    slidesPerView: 2,
                },
                658: {
                    spaceBetween: 15,
                    slidesPerView: 3,
                },
                768: {
                    spaceBetween: 15,
                    slidesPerView: 4,
                },
                1024: {
                    spaceBetween: 15,
                    slidesPerView: 5,
                },
            },
        });
    }

    if (document.querySelector(".news__slider")) {
        MetaSwiper(`.news__slider`, {
            loop: false,
            slidesPerView: 1,
            spaceBetween: 40,
            speed: 500,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
            breakpoints: {
                652: {
                    spaceBetween: 15,
                    slidesPerView: 2,
                },
                1024: {
                    spaceBetween: 15,
                    slidesPerView: 3,
                },
            },
            navigation: {
                nextEl: ".news__next",
                prevEl: ".news__prev",
            },
        });
    }

    if (document.querySelector(".mission__slider")) {
        MetaSwiper(`.mission__slider`, {
            loop: false,
            slidesPerView: 1,
            spaceBetween: 15,
            speed: 500,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
            breakpoints: {
                450: {
                    slidesPerView: 2,
                },
                700: {
                    slidesPerView: 3,
                },
                1024: {
                    slidesPerView: 4,
                },
            },
            navigation: {
                nextEl: ".mission__next",
                prevEl: ".mission__prev",
            },
        });
    }

    if (document.querySelector(".reviews")) {
        const sliderThumbs = new Swiper(".reviews__thumb", {
            direction: "horizontal",
            slidesPerView: 1,
            spaceBetween: 40,
            watchSlidesProgress: true,
            speed: 500,
            allowTouchMove: false,
            breakpoints: {
                770: {
                    direction: "horizontal",
                    slidesPerView: 3,
                    spaceBetween: 25,
                },
                1024: {
                    direction: "vertical",
                    freeMode: true,
                    slidesPerView: 3,
                    spaceBetween: 25,
                },
            },
        });
        const sliderText = new Swiper(".reviews__text", {
            direction: "horizontal",
            slidesPerView: 1,
            spaceBetween: 48,
            watchSlidesProgress: true,
            navigation: {
                nextEl: ".reviews__next",
                prevEl: ".reviews__prev",
            },
            speed: 500,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
            grabCursor: true,
            thumbs: {
                swiper: sliderThumbs,
            },
            breakpoints: {
                1024: {
                    direction: "vertical",
                },
            },
        });
    }

    if (document.querySelector(".supply__slider")) {
        resizableSwiper('(max-width: 1023px)', '.supply__slider',
            {
                slidesPerView: 1,
                spaceBetween: 15,
                speed: 500,
                autoplay: {
                    delay: 4000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                },
                navigation: {
                    nextEl: ".supply__next",
                    prevEl: ".supply__prev",
                },
                breakpoints: {
                    652: {
                        slidesPerView: 2,
                    },
                    900: {
                        slidesPerView: 3,
                    },
                },
            },
        );
    }

    if (document.querySelector(".details__slider")) {
        MetaSwiper(`.details__slider`, {
            loop: false,
            slidesPerView: 1,
            spaceBetween: 40,
            speed: 500,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
            breakpoints: {
                652: {
                    spaceBetween: 15,
                    slidesPerView: 2,
                },
                1024: {
                    spaceBetween: 15,
                    slidesPerView: 3,
                },
            },
            navigation: {
                nextEl: ".details__next",
                prevEl: ".details__prev",
            },
        });
    }

    if (document.querySelector(".testimonials__slider")) {
        MetaSwiper(`.testimonials__slider`, {
            loop: false,
            slidesPerView: 1,
            spaceBetween: 40,
            speed: 500,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
            navigation: {
                nextEl: ".testimonials__next",
                prevEl: ".testimonials__prev",
            },
        });
    }

    if (document.querySelector(".meet-team__slider")) {
        resizableSwiper('(max-width: 1023px)', '.meet-team__slider',
            {
                slidesPerView: 1,
                spaceBetween: 15,
                speed: 500,
                autoplay: {
                    delay: 4000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                },
                breakpoints: {
                    652: {
                        slidesPerView: 2,
                    },
                    900: {
                        slidesPerView: 3,
                    },
                },
                navigation: {
                    prevEl: ".meet-team__prev",
                    nextEl: ".meet-team__next",
                },
            },
        );
    }

    if (document.querySelector(".industries__slider")) {
        resizableSwiper('(max-width: 1023px)', '.industries__slider',
            {
                slidesPerView: 1,
                spaceBetween: 20,
                grid: {
                    rows: 2,
                },
                speed: 500,
                autoplay: {
                    delay: 4000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                },
                breakpoints: {
                    569: {
                        slidesPerView: 2,
                        grid: {
                            rows: 2,
                        },
                    },
                    768: {
                        slidesPerView: 3,
                        grid: {
                            rows: 2,
                        },
                    },
                },
                navigation: {
                    prevEl: ".industries__prev",
                    nextEl: ".industries__next",
                },
            },
        );
    }

    //  end swiper

    function customAccardion (triggers) {
        let triggersArr = document.querySelectorAll(triggers);

        triggersArr.forEach(trigger => {
            const closeBtn = trigger.querySelector('.arrow-btn');

            trigger.addEventListener("click", (e) => {
                if (e.target === closeBtn && trigger.classList.contains("active")) {
                    trigger.classList.remove("active");
                } else {
                    triggersArr.forEach(trigger => {
                        trigger.classList.remove("active");
                    });
                    trigger.classList.add("active");
                }
            });
        });
    }

    if (document.querySelector(".mission")) {
        customAccardion(".mission__card");
    }

    // awards
    const awardsBoxes = document.querySelectorAll(".awards__wrapper");

    if (awardsBoxes) {
        const awardsSwiper = new Swiper(`.awards__slider`, {
            loop: false,
            slidesPerView: 1,
            spaceBetween: 30,
            speed: 500,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
            navigation: {
                nextEl: ".awards__next",
                prevEl: ".awards__prev",
            },
            breakpoints: {
                374: {
                    spaceBetween: 40,
                    slidesPerView: 2,
                },
                658: {
                    spaceBetween: 40,
                    slidesPerView: 4,
                },
                768: {
                    spaceBetween: 50,
                    slidesPerView: 5,
                },
                1024: {
                    spaceBetween: 73,
                    slidesPerView: 7,
                },
            },
        });

        awardsBoxes.forEach(awardsBox => {
            const awards = awardsBox.querySelectorAll(".awards__logo");

            awards.forEach((item, index) => {
                const description = item.querySelector(".awards__logo-desc");

                function updatePosition () {
                    computePosition(item, description, {
                        placement: "top",
                        middleware: [
                            shift({
                                padding: 25,
                            }),
                            flip({
                                padding: 60
                            }),
                            offset({
                                mainAxis: 0,
                                crossAxis: 20,
                            }),
                        ],
                        boundary: document.querySelector("#site"),
                    }).then(({ x, y }) => {
                        Object.assign(description.style, {
                            left: `${x}px`,
                            top: `${y}px`,
                        });
                    });
                }

                const cleanup = autoUpdate(item, description, updatePosition);
            });
        });
    }
    // end awards

    // resources

    function customTabs (section, tabs, containers, slidersWrapper = false) {
        const tabTriggers = section.querySelectorAll(tabs);
        const tabContainers = section.querySelectorAll(containers);

        tabTriggers.forEach((tab, i) => {
            let newSlider;
            tab.addEventListener("click", () => {
                tabTriggers.forEach((tab, i) => {
                    tab.classList.remove("active");
                    tabContainers[i].classList.remove("active");
                });

                tab.classList.add("active");
                tabContainers[i].classList.add("active", "fade-in");

                if (slidersWrapper[i]) {
                    const prevArrow = slidersWrapper[i].querySelector('.slider-nav .arrow-btn:nth-child(1)');
                    const nextArrow = slidersWrapper[i].querySelector('.slider-nav .arrow-btn:nth-child(2)');
                    const slider = slidersWrapper[i].querySelector('.swiper');

                    newSlider = new Swiper(slider, {
                        loop: false,
                        slidesPerView: 1,
                        spaceBetween: 15,
                        speed: 500,
                        autoplay: {
                            delay: 4000,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: true,
                        },
                        navigation: {
                            nextEl: nextArrow,
                            prevEl: prevArrow,
                        },
                        breakpoints: {
                            450: {
                                slidesPerView: 2,
                            },
                            652: {
                                slidesPerView: 3,
                            },
                            1024: {
                                slidesPerView: 4,
                            },
                        },
                    });
                }
            });
        });
    }

    function topicsGrid () {
        const triggers = document.querySelectorAll(".topics__head");

        triggers.forEach(trigger => {
            const container = trigger.nextElementSibling;

            customTabs(container, ".topics__tab", ".topics__tab-content");

            trigger.addEventListener("click", e => {
                e.stopPropagation();
                if (trigger.classList.contains("open")) {
                    trigger.classList.remove("open");
                    trigger.nextElementSibling.classList.remove("open");
                } else {
                    triggers.forEach(trigger => {
                        trigger.classList.remove("open");
                        trigger.nextElementSibling.classList.remove("open");
                    });

                    trigger.classList.add("open");
                    trigger.nextElementSibling.classList.add("open");
                }
            });
        });
    }

    topicsGrid();

    function openCareerTopic () {
        const triggers = document.querySelectorAll(".topics__head");

        triggers.forEach((trigger, i) => {
            if (trigger.classList.contains("open")) {
                trigger.classList.remove("open");
                trigger.nextElementSibling.classList.remove("open");
            }

            if (i === 4) {
                trigger.classList.add("open");
                trigger.nextElementSibling.classList.add("open");
            }
        });
    }

    window.openCareerTopic = openCareerTopic;

    function firstGrid () {
        const triggers = document.querySelectorAll(".first__grid-head");

        triggers.forEach(trigger => {
            trigger.addEventListener("click", e => {
                if (trigger.classList.contains("open")) {
                    trigger.classList.remove("open");
                    trigger.nextElementSibling.classList.remove("open");
                } else {
                    triggers.forEach(trigger => {
                        trigger.classList.remove("open");
                        trigger.nextElementSibling.classList.remove("open");
                    });

                    trigger.classList.add("open");
                    trigger.nextElementSibling.classList.add("open");
                }
            });
        });
    }

    firstGrid();

    window.firstGrid = firstGrid;

    function resourcesAccardion (
        wrapperSelector,
        contentTrigger,
        contentSelector
    ) {
        const wrappers = document.querySelectorAll(wrapperSelector);

        wrappers.forEach(wrap => {
            const trigger = wrap.querySelector(contentTrigger);
            const content = wrap.querySelector(contentSelector);
            trigger.addEventListener("click", () => {
                let maxHeight = content.querySelector("div").clientHeight + "px";
                if (trigger.classList.contains("open")) {
                    trigger.classList.remove("open");
                    content.classList.remove("open");
                    content.style.maxHeight = 0;
                } else {
                    wrappers.forEach(wrap => {
                        const trigger = wrap.querySelector(contentTrigger);
                        const content = wrap.querySelector(contentSelector);

                        trigger.classList.remove("open");
                        content.classList.remove("open");
                        content.style.maxHeight = 0;
                    });
                    trigger.classList.add("open");
                    content.classList.add("open");
                    content.style.maxHeight = maxHeight;
                }
            });
        });
    }

    function updResourcesAccardion() {
        resourcesAccardion(
            ".topics__accardion",
            ".topics__accardion-head",
            ".topics__accardion-content"
        );
    }
    updResourcesAccardion();

    resourcesAccardion(
        ".first__accardion",
        ".first__accardion-head",
        ".first__accardion-content"
    );

    window.resourcesAccardion = resourcesAccardion;
    window.updResourcesAccardion = updResourcesAccardion;
    // end resources

    if (document.querySelector("#regions")) {
        const select = new Choices("#regions", {
            allowHTML: true,
            shouldSort: false,
        });
        //
        // const input = document.querySelector("#regions")
        // const hiddenInputWrap = document.querySelector(".contact-form__input-box--hidden")
        // const hiddenInput = document.querySelector(".contact-form__input-box--hidden input")
        //
        // input.addEventListener('change', () => {
        //     if (input.value === 'other') {
        //         hiddenInputWrap.classList.add('active')
        //     } else {
        //         hiddenInputWrap.classList.remove('active')
        //         hiddenInput.value = ''
        //     }
        // })
        // window.selectArr.push(select);
    }


    if (document.querySelector(".pay-rates__slider")) {
        let metaSwiper = new Swiper(".pay-rates__slider", {
            slidesPerView: 1,
            effect: "fade",
            fadeEffect: {
                crossFade: true,
            },
            speed: 400,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
                pauseOnMouseEnter: false,
            },
            navigation: {
                nextEl: '.pay-rates__next',
                prevEl: '.pay-rates__prev',
            },
        });

        window.updatePayRatesSwiper = function () {
            metaSwiper.update();
            metaSwiper.slideTo(0);
        };
    }
    const singleRangeSlider = () => {
        const singleRange = document.getElementsByClassName(
            "single-range-default"
        )[0];
        if (singleRange) {
            const slider = singleRange.querySelector("#single-range-default");
            const max = +slider.dataset.max;
            const min = +slider.dataset.min;
            const unit = slider.dataset?.unit || "£";
            const step = +slider.dataset.step;
            const inputsHidden = singleRange.querySelector(
                ".single-range-hidden-input"
            );
            const inputInfo = singleRange.querySelector(".single-range-info");
            const startValue = +inputsHidden.value;

            noUiSlider.create(slider, {
                start: [startValue],
                connect: "lower",
                step: step,
                range: {
                    min: min,
                    max: max,
                },
            });

            slider.noUiSlider.on("update", function (values, handle) {
                inputInfo.textContent = `${unit + Math.round(values[handle])} miles`;

                inputsHidden.value = Math.round(values[handle]);
            });
        }
    };
    singleRangeSlider();

    let filesInput = document.querySelectorAll('[type="file"]');
    if (filesInput) {
        filesInput.forEach(file => {
            file.addEventListener("change", function () {
                let uploadStatus =
                    this.closest(".upload-btn").querySelector(".upload-status");
                uploadStatus.innerText = this.files[0].name;
            });
        });
    }

    // /*map handler start*/
    if (document.querySelector(".map__slider")) {

        let swiper = new Swiper('.map__slider', {
            slidesPerView: 1,
            spaceBetween: 15,
            speed: 500,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
                pauseOnMouseEnter: false,
            },
            navigation: {
                nextEl: '.map__next',
                prevEl: '.map__prev',
            },
            breakpoints: {
                600: {
                    slidesPerView: 2,
                },
            },
        });
        window.updateMapSwiper = function () {
            const mapSliderWrapper = document.querySelector(".map__slider-box");
            const map = document.getElementById("map-container");

            if (!mapSliderWrapper.classList.contains("active")) {
                mapSliderWrapper.classList.add("active");
            }

            map.classList.add("active");

            swiper.update();
            swiper.slideTo(0);
            swiper.autoplay.start();
        };
    }
    window.initMap = function initMap (markers) {
        try {
            const map = document.getElementById("map-container");
            const mapSliderWrapper = document.querySelector('.map__slider-box');
            const mapHint = document.querySelector('.map-hint');
            let activeTitle = '';

            const map2 = new MetaMap("#map-container", {
                mapPath: site_dir + "public/images/map/gb.geo.json",
                zoomedCountries: "GB",
                zoomEnable: true,
                markers: markers,
                countryFillColor: "#F9DA41",
                countryStrokeWidth: "0",
                on: {
                    markerMouseEnter(pin, data) {
                        let mapRect = map.getBoundingClientRect();
                        let pinRect = pin.getBoundingClientRect();

                        const pins = document.querySelectorAll(".markers .marker");

                        mapHint.innerHTML = data.title;
                        mapHint.style.top = pinRect.y - mapRect.y + pinRect.height + "px";
                        mapHint.style.left = pinRect.x - mapRect.x + 10 + "px";
                        mapHint.style.transform = `translate(-${mapHint.clientWidth / 2}px,-${pinRect.height + mapHint.offsetHeight + 5}px)`;
                        let hintRect = mapHint.getBoundingClientRect();

                        if (hintRect.x < 10) {
                            let diff = innerWidth - 20 - (hintRect.x + hintRect.width);
                            mapHint.style.transform = `translate(${mapHint.clientWidth / 2 - diff}px,-${pinRect.height + mapHint.offsetHeight + 5}px)`;
                        }
                        if (hintRect.x + hintRect.width > innerWidth) {
                            let diff = innerWidth - 20 - (hintRect.x + hintRect.width);
                            mapHint.style.transform = `translate(-${mapHint.clientWidth / 2 - diff}px,-${pinRect.height + mapHint.offsetHeight + 5}px)`;
                        }

                        mapHint.classList.add("active");
                        map.classList.add("active");

                        pins.forEach((el) => {
                            el.classList.remove("active");
                        });
                        pin.classList.add("active");
                    },
                    markerMouseLeave(pin, data) {
                        const pins = document.querySelectorAll(".markers .marker");

                        mapHint.classList.remove("active");
                        map.classList.remove("active");

                        pins.forEach((el) => {
                            el.classList.remove("active");

                            if (el.classList.contains("is-slider")) {
                                map.classList.add("active");

                                let mapRect = map.getBoundingClientRect();
                                let pinRect = el.getBoundingClientRect();

                                mapHint.innerHTML = activeTitle;
                                mapHint.style.top = pinRect.y - mapRect.y + pinRect.height + "px";
                                mapHint.style.left = pinRect.x - mapRect.x + 10 + "px";
                                mapHint.style.transform = `translate(-${mapHint.clientWidth / 2}px,-${pinRect.height + mapHint.offsetHeight + 5}px)`;

                                let hintRect = mapHint.getBoundingClientRect();
                                if (hintRect.x < 10) {
                                    let diff = innerWidth - 20 - (hintRect.x + hintRect.width);
                                    mapHint.style.transform = `translate(${mapHint.clientWidth / 2 - diff}px,-${pinRect.height + mapHint.offsetHeight + 5}px)`;
                                }
                                if (hintRect.x + hintRect.width > innerWidth) {
                                    let diff = innerWidth - 20 - (hintRect.x + hintRect.width);
                                    mapHint.style.transform = `translate(-${mapHint.clientWidth / 2 - diff}px,-${pinRect.height + mapHint.offsetHeight + 5}px)`;
                                }

                                mapHint.classList.add("active");
                            }
                        });
                    },
                    markerClick (pin, data) {
                        if (!mapSliderWrapper.classList.contains("active")) {
                            mapSliderWrapper.classList.add("active");
                        }

                        if (!pin.classList.contains("is-slider")) {
                            let id = data.id;

                            const pins = document.querySelectorAll(".markers .marker");
                            map.classList.add("active");
                            pins.forEach((el) => {
                                el.classList.remove("active", "is-slider");
                            });
                            pin.classList.add("active", "is-slider");

                            load("page/search_map", `location=${id}`);
                            activeTitle = data.title;
                        } else {
                            return
                        }

                    },
                },
                markerStyle: {
                    img: site_dir + "public/images/pin.svg",
                    width: 1,
                    height: 2,
                },
            });


        } catch (e) {
            setTimeout(function () {
                initMap(locations);
            }, 500);
        }
    };

    if (document.querySelector('#map-container')) {
        setTimeout(function () {
            initMap(locations);
        }, 500);
    }
    /*map handler end*/

    if (location.hash) {
        const scrollSection = document.querySelector(location.hash);
        if (scrollSection) {
            setTimeout(function () {
                window.scrollTo(0, 0);
            }, 1);

            setTimeout(() => {
                scrollToCenter(location.hash);
            }, 500);
        }
    }

    function smoothScroll(allButtons) {
        allButtons.forEach((elem) => {
            elem.addEventListener("click", function (evt) {
                evt.preventDefault();
                const href = this.getAttribute("href");
                if (href) {
                    const anchor = href.split("#")[1] ? href.split("#")[1] : "";
                    const hrefWithoutAnchor = href.split("#")[0] ? href.split("#")[0] : "";

                    const currentHrefRAW = window.location.href.split("#")[0];
                    const currentHref = currentHrefRAW.endsWith("/")
                        ? currentHrefRAW.slice(0, -1)
                        : currentHrefRAW;

                    if (hrefWithoutAnchor === currentHref) {
                        scrollIntoView(document.querySelector(`#${anchor}`), {
                            time: 1000,
                        });
                    } else {
                        window.location.href = href;
                    }
                }
            });
        });
    }

    if (document.querySelector('.btn')) {
        const allButtons = document.querySelectorAll(".btn");
        smoothScroll(allButtons);
    }

    if (document.querySelector('.first__btn')) {
        const allButtons = document.querySelectorAll(".first__btn");
        smoothScroll(allButtons);
    }

    // plyr
    if (document.querySelector(".video-player")) {
        const videos = document.querySelectorAll(".video-player");

        videos.forEach(video => {
            const player = new Plyr(video, {
                controls: [
                    "play-large",
                    "play",
                    "progress",
                    "current-time",
                    "duration",
                    "mute",
                    "volume",
                    "captions",
                    "fullscreen",
                ],
            });

            player.on('play', () => {
                if (video.classList.contains('is-hide')) video.classList.remove('is-hide')
            })
        });
    }
    // end plyr

});

function scrollToCenter(selector) {
	const el = $(selector);

	$("html, body").animate(
		{
		  scrollTop: el.offset().top - window.innerHeight / 2 + el.height() / 2,
		},
		400
	);
  }
